import { useTranslation } from '@/components';
import { fontWeights, getTextColor, lightenHexColor } from '@/helpers';
import {
  Box,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { ItemProgress, StyledTable } from '.';

interface ReportItemRowProps {
  reports: SimpleReportTableAverageResponse[];
  relation: TopicRelationDetailedResponse;
}

const ReportItemRow = ({ reports, relation }: ReportItemRowProps) => {
  reports = reports.filter((x) => x.relationId === relation.id);

  return (
    <TableRow>
      <TableCell component='th'>{relation.relation}</TableCell>
      <TableCell>
        {reports.average((x) => x.value).toFixedString(1, '-')}
      </TableCell>
      <TableCell>{reports[0]?.benchmark || '-'}</TableCell>
      <TableCell sx={{ p: '4px 8px' }}>
        <ItemProgress
          user={reports.average((x) => x.value! * (x.relation?.impact || 1))}
          other={reports[0]?.benchmark}
        />
      </TableCell>
    </TableRow>
  );
};

interface IProps {
  id: string;
  reports: SimpleReportTableAverageResponse[];
  relations?: TopicRelationResponse[];
}

const TableAverage = ({ id, reports, relations }: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');

  const total = (reports: SimpleReportTableAverageResponse[]) => {
    return reports
      .filter((x) => !x.owner)
      .average(
        (x) => x.value! * (x.relation?.impact || 1),
        (x) => x.relation?.impact || 1
      )
      .toFixedString(1, '-');
  };

  const totalBenchmark = (reports: SimpleReportTableAverageResponse[]) => {
    return reports
      .filter((x) => !x.owner)
      .groupBy((x) => ({
        relationId: x.relationId,
        relation: x.relation
      }))
      .map((x) => x.items.average((y) => y.benchmark || 0))
      .average((x) => x)
      .toFixedString(1, '-');
  };

  return (
    <StyledTable>
      <TableHead>
        <TableRow key={'selfRate'}>
          <TableCell component='th' />
          <TableCell component='th' width='1%'>
            {t('Rating')}
          </TableCell>
          <TableCell
            component='th'
            width='1%'
            sx={{
              whiteSpace: {
                sm: 'nowrap'
              }
            }}
          >
            {t('CohortBenchmark')}
          </TableCell>
          <TableCell
            component='th'
            width='1%'
            sx={{
              whiteSpace: {
                sm: 'nowrap'
              }
            }}
          >
            {t('YoursVSOthers')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component='th'>{t('SelfRating')}</TableCell>
          <TableCell>
            {reports
              .filter((x) => x.owner)
              .average((x) => x.value)
              .toFixedString(1, '-')}
          </TableCell>
          <TableCell>
            {reports
              .filter((x) => x.owner)
              .average((x) => x.benchmark || 0)
              .toFixedString(1, '-')}
          </TableCell>
          <TableCell sx={{ p: '4px 8px' }}>
            <ItemProgress
              user={reports.filter((x) => x.owner).average((x) => x.value!)}
              other={reports
                .filter((x) => x.owner)
                .average((x) => x.benchmark || 0)}
            />
          </TableCell>
        </TableRow>
        {relations && relations.any() ? (
          relations.map((x) => (
            <ReportItemRow
              key={`item_${id}_relation_${x.id}`}
              reports={reports.filter((y) => !y.owner)}
              relation={x}
            />
          ))
        ) : (
          <ReportItemRow
            key={`item_${id}_relation_other`}
            reports={reports.filter((y) => !y.owner)}
            relation={{
              id: 'other',
              impact: 1,
              minResponses: 0,
              relation: 'Others'
            }}
          />
        )}
      </TableBody>
      <TableFooter>
        <TableRow
          sx={{
            '&>td': {
              fontSize: '16px',
              fontWeight: fontWeights.bold
            }
          }}
        >
          <TableCell
            sx={{
              boxShadow: 'none !important',
              textAlign: 'center',
              bgcolor: lightenHexColor(theme.palette.secondary.main, 80),
              p: '6px',
              color: getTextColor(
                lightenHexColor(theme.palette.secondary.main, 80)
              )
            }}
          >
            <Box fontWeight={fontWeights.semiBold}>{t('QuestionAverage')}</Box>
            <Box
              fontWeight={fontWeights.light}
              fontSize={{
                xs: '10px',
                sm: '11px',
                md: '13px'
              }}
            >
              ({t('ExcludingSelfRating')})
            </Box>
          </TableCell>
          <TableCell>{total(reports)}</TableCell>
          <TableCell>{totalBenchmark(reports)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableFooter>
    </StyledTable>
  );
};

export default TableAverage;
