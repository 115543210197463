import { fontWeights } from '@/helpers';
import Logo from '@/icons/logo';
import AppContext from '@contexts/app-context';
import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  Tooltip,
  useTheme
} from '@mui/material';
import React, { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const Header = () => {
  const { header } = useContext(AppContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const onBack = () => navigate(header.back!);

  return (
    <Box
      component='header'
      color={theme.palette.grey[800]}
      px={{
        xs: 3,
        sm: 4,
        md: 5
      }}
      pt={{
        xs: 1,
        sm: 2,
        md: 3
      }}
      mb={{
        xs: 1,
        sm: 2,
        md: 3
      }}
      height='48px'
      display='flex'
      alignItems='center'
    >
      {(header.back && typeof header.back === 'string' && (
        <Tooltip title='Back'>
          <IconButton onClick={onBack}>
            <ArrowBack />
          </IconButton>
        </Tooltip>
      )) ||
        header.back}
      <Logo />
      <Link
        component={RouterLink}
        lineHeight='12px'
        color='inherit'
        fontWeight={fontWeights.bold}
        fontSize='24px'
        ml={0.5}
        sx={{
          textDecoration: 'none'
        }}
        to={header.linkTo}
      >
        100 Facets
      </Link>
      {header.tools && header.tools.length > 0 && (
        <List sx={{ ml: 'auto', listStyle: 'none', p: 0 }}>
          {header.tools.map((x, i) => (
            <ListItem
              sx={{
                display: 'inline-block',
                width: 'auto',
                p: 0
              }}
              key={i}
            >
              {x}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default Header;
