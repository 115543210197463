import { fontWeights } from '@/helpers';
import { Box, Typography, useTheme } from '@mui/material';

interface IProps {
  nickname: string;
  date: string;
}

const TableReportHeader = ({ nickname, date }: IProps) => {
  const theme = useTheme();

  return (
    <Box
      bgcolor={theme.palette.secondary.main}
      color={theme.palette.secondary.contrastText}
      px={{
        xs: 2,
        sm: 3,
        md: 4
      }}
      py={{
        xs: 0.5,
        sm: 1,
        md: 2
      }}
      display='flex'
      alignItems='center'
    >
      <Typography
        variant='h1'
        fontSize={{
          xs: 16,
          sm: 24,
          md: 36
        }}
        mr={{
          xs: 4 / 12,
          sm: 8 / 12,
          md: 16 / 12
        }}
        fontWeight={fontWeights.semiBold}
        borderRight={
          date && `solid 2px ${theme.palette.secondary.contrastText}`
        }
        pr={{
          xs: 4 / 12,
          sm: 8 / 12,
          md: 16 / 12
        }}
      >
        {nickname}
      </Typography>
      {date && (
        <Typography
          variant='body1'
          fontSize={{
            xs: 12,
            sm: 16,
            md: 27
          }}
          fontWeight={fontWeights.light}
        >
          {date.asDateTime('MMMM yyyy')}
        </Typography>
      )}
    </Box>
  );
};

export default TableReportHeader;
