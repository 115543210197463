import { fontWeights, getCdnUrl } from '@/helpers';
import { Box, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import ChapterIcon from './chapter-icon';

type IProps = {
  index?: string;
  name: string;
  image?: string;
  section?: string;
  icon?: ReactNode;
};

const ChapterCover = ({ index, name, image, section, icon }: IProps) => {
  const theme = useTheme();

  return (
    <Box
      height='calc(100vh - 80px)'
      display='flex'
      flexDirection={{
        xs: 'column',
        md: 'row'
      }}
      bgcolor='white'
      sx={{
        breakInside: 'avoid',
        '@media print': {
          height: '100vh'
        }
      }}
    >
      <Box
        order={{
          xs: 0,
          md: 1
        }}
        flex={{
          xs: '50% 0 0',
          md: 'calc((540 / 811) * (100vh - 80px)) 0 0'
        }}
        sx={{
          backgroundImage: `url(${
            image || getCdnUrl('/images/reports/chapter.png')
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      />
      <Box
        order={{
          xs: 1,
          md: 0
        }}
        flex='auto 1 1'
        position='relative'
        sx={{
          backgroundImage: {
            xs: `url('${getCdnUrl('/images/reports/chapter-bg-xs.svg')}')`,
            md: `url('${getCdnUrl('/images/reports/chapter-bg.svg')}')`
          },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom left',
          backgroundSize: {
            xs: 'contain',
            md: 'auto'
          }
        }}
      >
        <Box
          position='absolute'
          bottom={{
            md: 'calc(50% + 32px)'
          }}
          top={{
            xs: 23,
            md: 'auto'
          }}
          left='40%'
          display='flex'
          flexDirection='row'
          alignItems='center'
        >
          {icon || (
            <ChapterIcon
              width={{
                xs: 20,
                md: 50
              }}
            />
          )}
          {section && (
            <Typography
              ml={0.5}
              fontSize={{
                xs: 18,
                sm: 24,
                md: 27
              }}
              fontWeight={fontWeights.bold}
            >
              {section}
            </Typography>
          )}
        </Box>
        <Box
          position='absolute'
          top={{
            xs: 55,
            md: '50%'
          }}
          right={0}
          height={1.4}
          width='60%'
          bgcolor={theme.palette.primary.main}
        />
        {index !== undefined && (
          <Box
            position='absolute'
            top={{
              xs: 65,
              md: 'calc(50% + 24px)'
            }}
            left='40%'
            fontSize={{
              xs: 12,
              md: 27
            }}
            fontWeight={fontWeights.extraLight}
          >
            {index}
          </Box>
        )}
        <Box
          position='absolute'
          top={{
            xs: 86,
            md: 'calc(50% + 66px)'
          }}
          left='40%'
          fontSize={{
            xs: 14,
            md: 27
          }}
          fontWeight={fontWeights.bold}
          color={theme.palette.secondary.main}
        >
          {name}
        </Box>
      </Box>
    </Box>
  );
};

export default ChapterCover;
