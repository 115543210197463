import App from '@/App';
import {
  LanguageChangedEvent,
  MultiLangRoute,
  Redirect,
  loadTranslation,
  setLanguage
} from '@/components';
import { LocalStorageKeys, UserRoles } from '@/enums';
import '@/extensions/string.extensions';
import {
  AvailableLanguages,
  LoadingEvent,
  Theme,
  getAuthCookie,
  getCdnUrl,
  getQueryString
} from '@/helpers';
import reportWebVitals from '@/reportWebVitals';
import * as serviceWorker from '@/sw';
import { ThemeProvider } from '@mui/material';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';

Object.defineProperties(Window.prototype, {
  hasReturnUrl: {
    get: function (this: Window) {
      return this.document.location.search.indexOf('returnUrl=') > -1;
    }
  },
  returnUrl: {
    get: function (this: Window) {
      if (!this.hasReturnUrl) {
        return '';
      }

      return getQueryString('returnUrl');
    }
  },
  returnUrlEncoded: {
    get: function (this: Window) {
      if (!this.hasReturnUrl) {
        return '';
      }

      return encodeURIComponent(this.returnUrl);
    }
  },
  user: {
    get: function (this: Window) {
      return (
        getAuthCookie() || {
          accessToken: '',
          name: '',
          email: '',
          secondaryEmail: '',
          role: UserRoles.Guest
        }
      );
    }
  }
});

Object.defineProperty(Location.prototype, 'pathAndSearch', {
  get: function (this: Location) {
    return `${this.pathname}${this.search}`;
  }
});

Object.defineProperty(Document.prototype, 'user', {
  get: function (this: Document) {
    const user = getAuthCookie();
    if (!user) {
      return {
        name: '',
        role: UserRoles.Guest,
        isAuthenticated: false
      };
    }

    return {
      name: user.name,
      role: user.role,
      isAuthenticated: true
    };
  }
});

const langCookie = document.cookie.getCookie('.johari.language') as Languages;
if (!!langCookie) {
  window._language = langCookie;
} else {
  for (let i = 0; i < navigator.languages.length; i++) {
    const lang = navigator.languages[i];
    for (let j = 0; j < AvailableLanguages.length; j++) {
      const avlang = AvailableLanguages[j];
      if (lang.split('-')[0].toLowerCase() === avlang.code.toLowerCase()) {
        window._language = avlang.code;
        break;
      }
    }

    if (!!window._language) {
      break;
    }
  }

  if (!window._language) {
    window._language = 'en';
  }
}

window._loadingCount = 0;
Object.defineProperty(window, 'loadingCount', {
  get: function (this: Window) {
    return this._loadingCount;
  }.bind(window),
  set: function (this: Window, v: number) {
    this._loadingCount = v < 0 ? 0 : v;

    const pageLoading = document.querySelector('.page-loading');

    if (this._loadingCount > 0) {
      if (pageLoading !== null) {
        return;
      }

      const loadingElem = this.document.createElement('div');
      loadingElem.className = 'page-loading';
      loadingElem.style.setProperty(
        '--theme-color',
        Theme.palette.primary.main
      );
      loadingElem.innerHTML = '<div><div></div><div></div></div>';

      document.body.prepend(loadingElem);

      return;
    }

    if (!pageLoading) {
      return;
    }

    pageLoading.classList.add('fadeOut');

    setTimeout(() => pageLoading.remove(), 1000);

    // TODO: Hide loading
  }.bind(window)
});

window.setLanguage = async function (this: Window, lang: Languages) {
  // await webClient.post(`/user/change-language/${lang}/`);
  document.documentElement.lang = lang;
  const event = new LanguageChangedEvent(window._language, lang);
  this._language = lang;
  localStorage.setItem(LocalStorageKeys.LANGUAGE, lang);
  window.dispatchEvent(event);
}.bind(window);

window.addEventListener('touchstart', (ev) => {
  if (ev.touches.length !== 1) {
    return;
  }

  const touch = ev.touches.item(0) as Touch;
  window.swipeData = {
    target: ev.target as any,
    x: touch.clientX,
    y: touch.clientY
  };
});

window.addEventListener('touchmove', (ev) => {
  if (!window.swipeData) {
    return;
  }

  const startPoint = window.swipeData;
  const touch = ev.touches.item(0) as Touch;
  const horizontalDiff = touch.clientX - startPoint.x;
  const verticalDiff = touch.clientY - startPoint.y;

  let swiped = false;
  let event: Event;
  if (horizontalDiff > 50) {
    event = new Event('swipeRight', {
      bubbles: true
    });
    startPoint.target.dispatchEvent(event);
    swiped = true;
  }
  if (horizontalDiff < -50) {
    event = new Event('swipeLeft', {
      bubbles: true
    });
    startPoint.target.dispatchEvent(event);
    swiped = true;
  }
  if (verticalDiff > 50) {
    event = new Event('swipeDown', {
      bubbles: true
    });
    startPoint.target.dispatchEvent(event);
    swiped = true;
  }
  if (verticalDiff < -50) {
    event = new Event('swipeUp', {
      bubbles: true
    });
    startPoint.target.dispatchEvent(event);
    swiped = true;
  }

  if (swiped) {
    window.swipeData = undefined;
  }
});

window.addEventListener('touchend', () => {
  window.swipeData = undefined;
});

window.addEventListener('languagechange', () => document.location.reload());

window.addEventListener('loading', async (event: LoadingEvent) => {
  window.loadingCount += event.show ? 1 : -1;
});

async function loadRoot() {
  await setLanguage(window._language);
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <BrowserRouter>
      <Helmet>
        <link
          rel='stylesheet'
          type='text/css'
          href={getCdnUrl(
            process.env.REACT_APP_CONFIG === 'develop'
              ? '/client.css'
              : '/client.min.css'
          )}
        />
      </Helmet>
      <Routes>
        <Route path='/en/*' element={<MultiLangRoute lang='en' />} />
        <Route path='/fr/*' element={<MultiLangRoute lang='fr' />} />
        <Route path='/de/*' element={<MultiLangRoute lang='de' />} />
        <Route path='/es/*' element={<MultiLangRoute lang='es' />} />
        <Route path='/pt/*' element={<MultiLangRoute lang='pt' />} />
        <Route path='/it/*' element={<MultiLangRoute lang='it' />} />
        <Route
          index
          element={<Redirect to={process.env.REACT_APP_LANDING} />}
        />
        {/* <Route index element={<SoundRecorder />} /> */}
        <Route
          path='/start/:id/'
          element={
            <Redirect
              beforeRedirect={() => {
                localStorage.removeItem(LocalStorageKeys.SURVEY_PROJECT);
                localStorage.setItem(
                  LocalStorageKeys.SURVEY_TOPIC,
                  useParams().id!
                );
              }}
              to='/survey/start/'
            />
          }
        />
        <Route
          path='*'
          element={
            <ThemeProvider theme={Theme}>
              <App />
            </ThemeProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

async function init() {
  try {
    const res = await axios.get<Record<string, string>>('/versions.json');
    window.versions = res.data;

    await loadTranslation('en');
    if (window._language !== 'en') {
      const loaded = await loadTranslation(window._language);
      if (!loaded) {
        window._language = 'en';
      }
    }

    serviceWorker.register();
    await loadRoot();
  } catch (exp) {
    console.log(exp);
  }
}

init();
