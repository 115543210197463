import { MultiLangComponent, Redirect } from '@/components';
import { Authenticate } from '@/components/authenticate';
import { UserRoles } from '@/enums';
import { clearAuthCookie } from '@/helpers';
import { CodeRouter, NotFound, Terms } from '@/pages';
import Privacy from '@/pages/privacy';
import AppContext, { AppContextType } from '@contexts/app-context';
import DiagnoseDone from '@diagnose/done';
import DiagnoseReport from '@diagnose/report';
import DiagnoseStart from '@diagnose/survey';
import { Login, Person } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import SurveyChart from '@survey/chart';
import SurveyFeedback from '@survey/feedback';
import SurveyIndex from '@survey/index';
import SurveyNotAvailable from '@survey/not-available';
import SurveyNotFound from '@survey/not-found';
import SurveyReport from '@survey/report/survey-report';
import TeamReport from '@survey/report/team-report';
import SurveyShare from '@survey/share';
import SurveyThanks from '@survey/thanks';
import { Route, Routes } from 'react-router';

interface IState {
  anchorElement?: Element;
  openProfile: boolean;
}

type IProps = HasNavigationAccess & {
  openProfile: (e: React.MouseEvent<HTMLElement>) => Promise<void>;
};

export default class Layout extends MultiLangComponent<IProps, IState> {
  static contextType = AppContext;
  declare context: AppContextType;

  state: IState = {
    openProfile: false
  };

  componentDidMount() {
    super.componentDidMount();
  }

  async onAuthorized() {
    let tools = [
      <Tooltip title={this.t('Login')} key={'loginButton'}>
        <IconButton onClick={() => this.props.navigate('/login')}>
          <Login />
        </IconButton>
      </Tooltip>
    ];

    if (window.user.role === UserRoles.User) {
      tools = [
        <Tooltip title={window.user.name} key={'profileButton'}>
          <IconButton
            onClick={this.props.openProfile}
            aria-controls={this.state.openProfile ? 'profile' : undefined}
            aria-haspopup='true'
            aria-expanded={this.state.openProfile ? 'true' : undefined}
          >
            <Person />
          </IconButton>
        </Tooltip>
      ];
    }

    if (
      [UserRoles.Admin, UserRoles.SuperAdmin].indexOf(window.user.role) > -1
    ) {
      tools = [
        <Tooltip title={this.t('Admin')} key={'adminButton'}>
          <IconButton onClick={() => this.props.navigate('/admin')}>
            <Person />
          </IconButton>
        </Tooltip>
      ];
    }

    await this.context.setHeader('/', undefined, tools);
  }

  render(): React.ReactNode {
    return (
      <>
        <Routes>
          <Route
            path='/diagnose/survey/:surveyId'
            index
            element={<Authenticate component={<DiagnoseStart />} />}
          />
          <Route
            path='/diagnose/survey/:surveyId/done'
            index
            element={<Authenticate component={<DiagnoseDone />} />}
          />
          <Route
            path='/sign-out'
            index
            element={
              <Authenticate
                component={
                  <Redirect to='/' beforeRedirect={() => clearAuthCookie()} />
                }
                unauthorized={<Redirect to='/' />}
              />
            }
          />
          <Route
            path='/diagnose/:diagnoseId'
            index
            element={<Authenticate component={<DiagnoseReport />} />}
          />
          <Route path='/reports/survey/:id' index element={<SurveyReport />} />
          <Route
            path='/reports/project/:projectId/team/:teamId'
            element={<TeamReport />}
          />
          <Route path='/survey/start' element={<SurveyIndex />} />
          <Route path='/survey/feedback' element={<SurveyFeedback />} />
          <Route path='/survey/share' element={<SurveyShare />} />
          <Route
            path='/survey/not-available'
            element={<SurveyNotAvailable />}
          />
          <Route path='/survey/not-found' element={<SurveyNotFound />} />
          <Route path='/survey/:id' element={<SurveyChart />} />
          <Route path='/feedback/thanks' element={<SurveyThanks />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/:code' element={<CodeRouter />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </>
    );
  }
}
