import { useTranslation } from '@/components';
import { fontWeights, getTextColor, lightenHexColor } from '@/helpers';
import { Check } from '@mui/icons-material';
import {
  Box,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { ItemProgress, StyledTable } from '.';

interface IProps {
  id: string;
  options: TopicOptionResponse[];
  reports: SimpleReportTableAnonymousResponse[];
}

const TableAnonymous = ({ id, options, reports }: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');

  const total = (reports: SimpleReportTableAnonymousResponse[]) => {
    return reports
      .filter((x) => !x.option.optional && !x.owner)
      .average((x) => x.option.value)
      .toFixedString(1, '-');
  };

  const totalBenchmark = (reports: SimpleReportTableAnonymousResponse[]) => {
    return reports
      .filter((x) => !x.option.optional && !x.owner)
      .average((x) => x.option.value)
      .toFixedString(1, '-');
  };

  return (
    <StyledTable>
      <TableHead>
        <TableRow key={'selfRate'}>
          <TableCell component='th' />
          {options.map((y) => (
            <TableCell
              key={`item_${id}_option_${y.id}_title`}
              component='th'
              width='1%'
            >
              {y.title}
            </TableCell>
          ))}
          <TableCell component='th' width='1%'>
            {t('Average')}
          </TableCell>
          <TableCell
            component='th'
            width='1%'
            sx={{
              whiteSpace: {
                sm: 'nowrap'
              }
            }}
          >
            {t('CohortBenchmark')}
          </TableCell>
          <TableCell
            component='th'
            width='1%'
            sx={{
              whiteSpace: {
                sm: 'nowrap'
              }
            }}
          >
            {t('YoursVSOthers')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component='th'>{t('SelfRating')}</TableCell>
          {options.map((x) => (
            <TableCell key={`item_${id}_self_option_${x.id}_value`}>
              {reports.any((y) => y.owner && y.option.value === x.value) && (
                <Check />
              )}
            </TableCell>
          ))}
          <TableCell>
            {reports
              .filter((x) => x.owner && !x.option.optional)
              .average((x) => x.option.value!)
              .toFixedString(1, '-')}
          </TableCell>
          <TableCell>
            {reports
              .filter((x) => x.owner)
              .average((x) => x.benchmark || 0)
              .toFixedString(1, '-')}
          </TableCell>
          <TableCell sx={{ p: '4px 8px' }}>
            <ItemProgress
              user={reports
                .filter((x) => x.owner && !x.option.optional)
                .average((x) => x.option.value!)}
              other={reports
                .filter((x) => x.owner)
                .average((x) => x.benchmark || 0)}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component='th'>{t('Others')}</TableCell>
          {options.map((x) => (
            <TableCell key={`item_${id}_others_option_${x.id}_value`}>
              {reports.any((y) => !y.owner && y.option.value === x.value) && (
                <Check />
              )}
            </TableCell>
          ))}
          <TableCell>
            {reports
              .filter((x) => !x.owner && !x.option.optional)
              .average((x) => x.option.value!)
              .toFixedString(1, '-')}
          </TableCell>
          <TableCell>
            {reports
              .filter((x) => !x.owner)
              .average((x) => x.benchmark || 0)
              .toFixedString(1, '-')}
          </TableCell>
          <TableCell sx={{ p: '4px 8px' }}>
            <ItemProgress
              user={reports
                .filter((x) => !x.owner && !x.option.optional)
                .average((x) => x.option.value!)}
              other={reports
                .filter((x) => !x.owner)
                .average((x) => x.benchmark || 0)}
            />
          </TableCell>
        </TableRow>
      </TableBody>
      <TableFooter>
        <TableRow
          sx={{
            '&>td': {
              fontSize: '16px',
              fontWeight: fontWeights.bold
            }
          }}
        >
          <TableCell />
          <TableCell
            colSpan={options.length}
            sx={{
              boxShadow: 'none !important',
              textAlign: 'center',
              bgcolor: lightenHexColor(theme.palette.secondary.main, 80),
              p: '6px',
              color: getTextColor(
                lightenHexColor(theme.palette.secondary.main, 80)
              )
            }}
          >
            <Box fontWeight={fontWeights.semiBold}>{t('QuestionAverage')}</Box>
            <Box
              fontWeight={fontWeights.light}
              fontSize={{
                xs: '10px',
                sm: '11px',
                md: '13px'
              }}
            >
              ({t('ExcludingSelfRating')})
            </Box>
          </TableCell>
          <TableCell>{total(reports)}</TableCell>
          <TableCell>{totalBenchmark(reports)}</TableCell>
          <TableCell />
        </TableRow>
      </TableFooter>
    </StyledTable>
  );
};

export default TableAnonymous;
