import { useTranslation } from '@/components';
import { fontWeights, getTextColor, lightenHexColor } from '@/helpers';
import { Check } from '@mui/icons-material';
import {
  Box,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { ItemProgress, StyledTable } from '.';

interface ReportItemRowProps {
  reports: SimpleReportTableFamiliarityResponse[];
  familiarity: TopicFamiliarityOptionResponse;
  options: TopicOptionResponse[];
}

const ReportItemRow = ({
  reports,
  familiarity,
  options
}: ReportItemRowProps) => {
  reports = reports.filter((x) => x.familiarityId === familiarity.id);

  const counTableRowesponds = (
    reports: SimpleReportTableFamiliarityResponse[],
    option: TopicOptionResponse
  ) => {
    const count = reports.count((x) => x.option.value === option.value);
    if (count === 0) {
      return '';
    }

    return count.toString();
  };

  return (
    <TableRow>
      <TableCell component='th'>{familiarity.title}</TableCell>
      {options.map((x, i) => (
        <TableCell key={i}>{counTableRowesponds(reports, x)}</TableCell>
      ))}
      <TableCell>
        {reports
          .filter((x) => !x.option.optional)
          .average((x) => x.option.value!)
          .toFixedString(1, '-')}
      </TableCell>
      <TableCell>{reports[0]?.benchmark || '-'}</TableCell>
      <TableCell sx={{ p: '4px 8px' }}>
        <ItemProgress
          user={reports
            .filter((x) => !x.option.optional)
            .average((x) => x.option.value!)}
          other={reports[0]?.benchmark}
        />
      </TableCell>
    </TableRow>
  );
};

interface IProps {
  id: string;
  options: TopicOptionResponse[];
  reports: SimpleReportTableFamiliarityResponse[];
  familiarity?: TopicFamiliarityResponse;
}

const TableFamiliarity = ({ id, options, reports, familiarity }: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');

  const total = (reports: SimpleReportTableFamiliarityResponse[]) => {
    return reports
      .filter((x) => !x.option.optional && !x.owner)
      .average((x) => x.option.value!)
      .toFixedString(1, '-');
  };

  const totalBenchmark = (reports: SimpleReportTableFamiliarityResponse[]) => {
    return reports
      .filter((x) => !x.option.optional && !x.owner)
      .groupBy((x) => ({
        familiarityId: x.familiarityId,
        familiarity: x.familiarity
      }))
      .map((x) => x.items.average((y) => y.benchmark || 0))
      .average((x) => x)
      .toFixedString(1, '-');
  };

  return (
    <StyledTable>
      <TableHead>
        <TableRow key={'selfRate'}>
          <TableCell component='th' />
          {options.map((y) => (
            <TableCell
              key={`item_${id}_option_${y.id}_title`}
              component='th'
              width='1%'
            >
              {y.title}
            </TableCell>
          ))}
          <TableCell component='th' width='1%'>
            {t('Average')}
          </TableCell>
          <TableCell
            component='th'
            width='1%'
            sx={{
              whiteSpace: {
                sm: 'nowrap'
              }
            }}
          >
            {t('CohortBenchmark')}
          </TableCell>
          <TableCell
            component='th'
            width='1%'
            sx={{
              whiteSpace: {
                sm: 'nowrap'
              }
            }}
          >
            {t('YoursVSOthers')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component='th'>{t('SelfRating')}</TableCell>
          {options.map((x) => (
            <TableCell key={`item_${id}_self_option_${x.id}_value`}>
              {reports.any(
                (y) => y.owner === true && y.option.value === x.value
              ) && <Check />}
            </TableCell>
          ))}
          <TableCell>
            {reports
              .filter((x) => x.owner && !x.option.optional)
              .average((x) => x.option.value!)
              .toFixedString(1, '-')}
          </TableCell>
          <TableCell>
            {reports
              .filter((x) => x.owner)
              .average((x) => x.benchmark || 0)
              .toFixedString(1, '-')}
          </TableCell>
          <TableCell sx={{ p: '4px 8px' }}>
            <ItemProgress
              user={reports
                .filter((x) => x.owner && !x.option.optional)
                .average((x) => x.option.value!)}
              other={reports
                .filter((x) => x.owner)
                .average((x) => x.benchmark || 0)}
            />
          </TableCell>
        </TableRow>
        {familiarity &&
          familiarity.options.map((x) => (
            <ReportItemRow
              key={`item_${id}_relation_${x.id}`}
              reports={reports.filter((y) => !y.owner)}
              familiarity={x}
              options={options}
            />
          ))}
      </TableBody>
      <TableFooter>
        <TableRow
          sx={{
            '&>td': {
              fontSize: '16px',
              fontWeight: fontWeights.bold
            }
          }}
        >
          <TableCell />
          <TableCell
            colSpan={options.length}
            sx={{
              boxShadow: 'none !important',
              textAlign: 'center',
              bgcolor: lightenHexColor(theme.palette.secondary.main, 80),
              p: '6px',
              color: getTextColor(
                lightenHexColor(theme.palette.secondary.main, 80)
              )
            }}
          >
            <Box fontWeight={fontWeights.semiBold}>{t('QuestionAverage')}</Box>
            <Box
              fontWeight={fontWeights.light}
              fontSize={{
                xs: '10px',
                sm: '11px',
                md: '13px'
              }}
            >
              ({t('ExcludingSelfRating')})
            </Box>
          </TableCell>
          <TableCell>{total(reports)}</TableCell>
          <TableCell>{totalBenchmark(reports)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableFooter>
    </StyledTable>
  );
};

export default TableFamiliarity;
