import {
  AdminsAuthenticate,
  Authenticate,
  getLanguage,
  Header,
  Loading,
  MultiLangComponent,
  setLanguage,
  useTranslation
} from '@/components';
import { AvailableLanguages, fontWeights, getCdnUrl, Logger } from '@/helpers';
import { webClient } from '@/helpers/webClient';
import { Globe04 } from '@/icons';
import { ErrorComponent, Layout } from '@/pages';
import RecoverPassword from '@auth/recover-password';
import SignIn from '@auth/signIn';
import SignUpThanks from '@auth/signUpThanks';
import SignUp from '@auth/signup';
import Verify from '@auth/verify';
import AppContext from '@contexts/app-context';
import {
  Box,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useTheme
} from '@mui/material';
import UserRouter from '@userRoot/userRouter';
import { AxiosError } from 'axios';
import React, { ReactNode, Suspense } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

const LazyAdminRouter = React.lazy(() => import('@adminRoot/adminRouter'));

interface IToast {
  id: number;
  content: string;
  closing: boolean;
  type: ToastTypes;
}

interface IProps {}
interface IState {
  settings: ISettings;
  openLanguagesList: boolean;
  openProfile: boolean;
  anchorElement: Element | null;
  loadingCount: number;
  toasts: IToast[];
  mainClasses: Record<MainAvailableClasses, boolean>;
  header: {
    linkTo: string;
    back?: string;
    tools: ReactNode[] | undefined;
  };
  error: FormError;
  dialog: {
    isOpen: boolean;
    title: string;
    content?: string | ReactNode;
    actions: ReactNode;
  };
}

const defaltSettings: ISettings = {
  APP_NAME: 'GrowPass',
  base: '',
  surveys: {
    target: 3
  }
};

class App extends MultiLangComponent<
  HasNavigationAccess & HasThemeAccess & HasSettingsAccess,
  IState
> {
  state: IState = {
    settings: defaltSettings,
    openLanguagesList: false,
    openProfile: false,
    anchorElement: null,
    mainClasses: {
      'main-flex': true,
      dark: false,
      'no-padding': false
    },
    loadingCount: 0,
    toasts: [],
    header: {
      linkTo: '/',
      tools: []
    },
    error: false,
    dialog: {
      isOpen: false,
      title: '',
      actions: <></>
    }
  };

  private _toastId: number = 0;

  constructor(props: any) {
    super(props);

    webClient.interceptors.response.use(
      (x) => x,
      this.redirectOnResponseError.bind(this)
    );

    this.openLanguagesList = this.openLanguagesList.bind(this);
    this.closeLanguagesList = this.closeLanguagesList.bind(this);
    this.openProfile = this.openProfile.bind(this);
    this.closeProfile = this.closeProfile.bind(this);
    this.setMainClasses = this.setMainClasses.bind(this);
    this.showToast = this.showToast.bind(this);
    this.setHeader = this.setHeader.bind(this);
    this.setError = this.setError.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  async componentDidMount() {
    super.componentDidMount();

    let state = {
      settings: defaltSettings
    };

    try {
      const settings = await webClient.get<
        SingleResponse<{ settings: ISettings }>
      >('/settings');
      document.title = settings.data.data.settings.APP_NAME || 'GrowPass';
      state.settings = settings.data.data.settings;
    } catch (exp) {}

    await this.setStateAsync(state);
  }

  private async redirectOnResponseError(error: AxiosError) {
    if (error.config?.authHandled === true) {
      throw error;
    }

    if (document.location.pathname.startsWith('/login')) {
      return;
    }

    if (error.response?.status === 403) {
      return this.setError("You don't have access to this page!");
    }

    if (error.response?.status === 401) {
      const lang = getLanguage();
      return this.props.navigate(
        `/login/?returnUrl=%2F${lang}${encodeURIComponent(
          document.location.pathAndSearch
        )}`,
        {
          replace: true
        }
      );
    }

    throw error;
  }

  private async showDialog(title: string): Promise<void>;
  private async showDialog(title: string, actions: ReactNode): Promise<void>;
  private async showDialog(
    title: string,
    content: string | ReactNode,
    actions: ReactNode
  ): Promise<void>;
  private async showDialog(
    title: string,
    contentActions?: string | ReactNode,
    actions?: ReactNode
  ): Promise<void> {
    const content = typeof actions === 'undefined' ? undefined : contentActions;
    const buttons = typeof actions === 'undefined' ? contentActions : actions;

    this.setStateAsync({
      dialog: {
        isOpen: true,
        title,
        content,
        actions: buttons
      }
    });
  }

  private async hideDialog() {
    let dialog = this.state.dialog;
    dialog.isOpen = false;
    await this.setStateAsync({
      dialog
    });
  }

  private async setError(error: FormError) {
    await this.setStateAsync({ error });
  }

  private async closeLanguagesList() {
    await this.setStateAsync({ openLanguagesList: false });
  }

  private async openLanguagesList(e: React.MouseEvent<HTMLElement>) {
    await this.setStateAsync({
      openProfile: false,
      openLanguagesList: true,
      anchorElement: e.currentTarget
    });
  }

  async openProfile(e: React.MouseEvent<HTMLElement>) {
    await this.setStateAsync({
      anchorElement: e.currentTarget,
      openLanguagesList: false,
      openProfile: true
    });
  }

  async closeProfile() {
    await this.setStateAsync({
      openProfile: false
    });
  }

  async setMainClasses(...classes: MainAvailableClasses[]) {
    let state = this.state.mainClasses;
    let classNames = Object.keys(state) as MainAvailableClasses[];

    for (let i = 0; i < classNames.length; i++) {
      const className = classNames[i];
      state[className] = classes.indexOf(className) > -1;
    }

    await this.setStateAsync({ mainClasses: state });
  }

  async setHeader(to?: string, back?: string, ...tools: ReactNode[]) {
    let header = this.state.header;

    if (to) {
      header.linkTo = to;
    }

    header.back = back;
    header.tools = tools;

    await this.setStateAsync({ header });
  }

  async showLoading() {
    Logger.log(`Call show loading: ${this.state.loadingCount}`);
    await this.setStateAsync({
      loadingCount: this.state.loadingCount + 1
    });
  }

  async hideLoading() {
    Logger.log(`Call hide loading: ${this.state.loadingCount}`);
    if (this.state.loadingCount <= 0) {
      return;
    }

    await this.setStateAsync({
      loadingCount: this.state.loadingCount - 1
    });
  }

  async showToast(content: string, duration?: number, type?: ToastTypes) {
    const id = this._toastId++;
    await this.setStateAsync({
      toasts: [
        ...this.state.toasts,
        {
          id,
          content,
          type: type || 'default',
          closing: false
        }
      ]
    });

    setTimeout(
      async function (this: App, id: number) {
        let i = this.state.toasts.findIndex((x) => x.id === id);
        if (i < 0) {
          return;
        }

        let toasts = [...this.state.toasts];
        let toast = { ...toasts[i] };
        toast.closing = true;

        toasts[i] = toast;
        await this.setStateAsync({ toasts });
        setTimeout(
          async function (this: App, id: number) {
            let i = this.state.toasts.findIndex((x) => x.id === id);
            if (i < 0) {
              return;
            }

            let toasts = [...this.state.toasts];
            toasts = toasts.filter((x) => x.id !== id);
            await this.setStateAsync({ toasts });
          }.bind(this, id),
          1e3
        );
      }.bind(this, id),
      duration || 2e3
    );
  }

  private get headerTools() {
    let res: ReactNode[] = [];
    if (AvailableLanguages.length > 1) {
      res.push(
        <Tooltip title={this.t('Language')} key={'languagesButton'}>
          <IconButton
            onMouseEnter={this.openLanguagesList}
            onClick={this.openLanguagesList}
            aria-controls={
              this.state.openLanguagesList ? 'languages-list' : undefined
            }
            aria-haspopup='true'
            aria-expanded={this.state.openLanguagesList ? 'true' : undefined}
            sx={{
              bgcolor: this.state.openLanguagesList ? '#F9ECFF' : undefined,
              borderRadius: '10px',
              color: '#414042'
            }}
          >
            <Globe04 />
            <Box
              fontSize={{
                xs: '16px',
                sm: '20px',
                md: '24px'
              }}
              fontWeight={fontWeights.bold}
              fontFamily='monospace'
            >
              {getLanguage().toUpperCase()}
            </Box>
          </IconButton>
        </Tooltip>
      );
    }

    if (!!this.state.header.tools) {
      res = [...res, ...this.state.header.tools];
    }

    return res;
  }

  render(): React.ReactNode {
    return (
      <AppContext.Provider
        value={{
          header: {
            ...this.state.header,
            tools: this.headerTools
          },
          settings: this.state.settings,
          setHeader: this.setHeader.bind(this),
          setMainClasses: this.setMainClasses.bind(this),
          showToast: this.showToast.bind(this),
          setError: this.setError.bind(this),
          showDialog: this.showDialog.bind(this),
          hideDialog: this.hideDialog.bind(this)
        }}
      >
        <Dialog
          open={this.state.dialog.isOpen}
          onClose={this.hideDialog}
          PaperProps={{
            sx: {
              minWidth: 400
            }
          }}
        >
          <DialogTitle>{this.state.dialog.title}</DialogTitle>
          {this.state.dialog.content && (
            <DialogContent>{this.state.dialog.content}</DialogContent>
          )}
          {this.state.dialog.actions && (
            <DialogActions>{this.state.dialog.actions}</DialogActions>
          )}
        </Dialog>
        <CssBaseline />
        <Box
          height='100vh'
          display='flex'
          flexDirection='column'
          sx={{
            overflowY: 'auto'
          }}
          color={this.props.theme.palette?.text.primary}
        >
          {AvailableLanguages.length > 1 && (
            <Menu
              anchorEl={this.state.anchorElement}
              open={this.state.openLanguagesList}
              onClose={this.closeLanguagesList}
              onClick={this.closeLanguagesList}
              slotProps={{
                paper: {
                  onMouseLeave: this.closeLanguagesList,
                  elevation: 0,
                  sx: {
                    borderRadius: '24px',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    py: 1,
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    }
                  }
                }
              }}
              transformOrigin={{ horizontal: 'center', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
              {AvailableLanguages.map((x) => (
                <MenuItem
                  key={x.code}
                  onClick={async () => await setLanguage(x.code)}
                  sx={{
                    bgcolor: getLanguage() === x.code ? '#F9ECFF' : undefined,
                    px: 32 / 12,
                    minHeight: 'auto'
                  }}
                >
                  <Box fontFamily='monospace' component='strong' mr={0.5}>
                    {x.code.toUpperCase()}
                    {' |'}
                  </Box>
                  {x.name}
                </MenuItem>
              ))}
            </Menu>
          )}
          <Menu
            anchorEl={this.state.anchorElement}
            open={this.state.openProfile}
            onClose={this.closeProfile}
            onClick={this.closeProfile}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => (document.location.href = '/sign-out')}>
              {this.t('Logout')}
            </MenuItem>
          </Menu>
          <Header />
          <Box
            component='main'
            p={this.state.mainClasses['no-padding'] ? 0 : 2.5}
            minHeight='calc(100vh - 84px)'
            bgcolor={
              this.state.mainClasses.dark
                ? this.props.theme.palette.grey[800]
                : undefined
            }
            color={this.state.mainClasses.dark ? 'white' : undefined}
            display={this.state.mainClasses['main-flex'] ? 'flex' : undefined}
            flexDirection='column'
            sx={{
              overflowX: 'hidden',
              '@media print': {
                bgcolor: 'white',
                p: 0
              }
            }}
          >
            {this.state.loadingCount > 0 && (
              <Box
                position='fixed'
                height='calc(100vh - 4rem)'
                top='4rem'
                left={0}
                width='100vw'
                zIndex={1000}
                bgcolor='#fff7'
                display='flex'
              >
                <Loading />
              </Box>
            )}
            {this.state.toasts.length > 0 && (
              <Box
                position='fixed'
                top='84px'
                left='32px'
                right='32px'
                zIndex={10000000}
              >
                {this.state.toasts.map((toast) => (
                  <Box
                    key={toast.id}
                    lineHeight='19px'
                    bgcolor={
                      toast.type === 'error'
                        ? '#feeded'
                        : toast.type === 'info'
                        ? '#e6ebff'
                        : toast.type === 'success'
                        ? '#f2fdf6'
                        : '#f5f5f5'
                    }
                    color={
                      toast.type === 'error'
                        ? this.props.theme.palette.error.main
                        : toast.type === 'info'
                        ? this.props.theme.palette.info.main
                        : toast.type === 'success'
                        ? this.props.theme.palette.success.main
                        : this.props.theme.palette.secondary.main
                    }
                    fontSize='15px'
                    fontWeight={fontWeights.semiBold}
                    borderRadius='10px'
                    px={1.5}
                    py={1}
                    mb={1.5}
                    display='flex'
                    sx={{
                      animation: toast.closing
                        ? 'hideToast 0.5s ease-in-out 1'
                        : 'showToast 1s ease-in-out 1',
                      top: toast.closing ? '-500px' : undefined
                    }}
                  >
                    {toast.type !== 'default' && (
                      <img
                        style={{ marginRight: '8px' }}
                        src={getCdnUrl(`/images/${toast.type}.svg`)}
                        alt={toast.type}
                      />
                    )}
                    {toast.content}
                  </Box>
                ))}
              </Box>
            )}
            {(this.state.error && (
              <ErrorComponent
                code={this.state.error}
                context={{
                  setError: this.setError.bind(this)
                }}
              />
            )) || (
              <Routes>
                <Route path='/login' element={<SignIn />} />
                <Route path='/register/thanks' element={<SignUpThanks />} />
                <Route path='/register' element={<SignUp />} />
                <Route path='/verify' element={<Verify />} />
                <Route path='/recover-password' element={<RecoverPassword />} />
                <Route
                  path='/admin/*'
                  element={
                    <AdminsAuthenticate
                      component={
                        <Suspense fallback={<CircularProgress />}>
                          <LazyAdminRouter />
                        </Suspense>
                      }
                    />
                  }
                />
                <Route
                  path='/user/*'
                  element={<Authenticate component={<UserRouter />} />}
                />
                <Route
                  path='*'
                  element={
                    <Layout
                      navigate={this.props.navigate}
                      openProfile={this.openProfile}
                      t={this.t}
                    />
                  }
                />
              </Routes>
            )}
          </Box>
        </Box>
      </AppContext.Provider>
    );
  }
}

export default function AppFunction(props: IProps) {
  return (
    <App
      {...props}
      navigate={useNavigate()}
      t={useTranslation()}
      theme={useTheme()}
      settings={defaltSettings}
    />
  );
}
