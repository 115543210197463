import { useTranslation } from '@/components';
import SoundRecorder from '@/components/sound-recorder';
import { RectifyType } from '@/enums';
import { addAlphaToHex, fontWeights, webClient } from '@/helpers';
import WriterSvg from '@/images/writer';
import { Keyboard, Mic, Send } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { useState } from 'react';

interface IProps {
  topic: SurveyToResponseResponse | TopicSurveyResponse;
  project?: string;
  code?: string;
  itemId: string;
  optionId: string;
  topicId?: string;
  submit: Func1<CreateSurveyRespondItemBody[], Promise<void>>;
}

const minimumNeed = 6e4;
const dataMinLength = 20;

const OpenEnded = ({
  submit,
  itemId,
  optionId,
  code,
  project,
  topic,
  topicId
}: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Feedback.OpenEnded');

  let soundRecorder: {
    startOver(): void;
  } = {
    startOver() {}
  };

  const [inputType, setInputType] = useState<('text' | 'voice') | undefined>();
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const [data, setData] = useState<Blob | string | undefined>();
  const [result, setResult] = useState<string | undefined>();
  const [recordedTime, setRecordedTime] = useState(0);

  const stringData = () =>
    !data || typeof data !== 'string' ? '' : (data as string).trim();
  const blobData = () => data as Blob;

  const submitResult = async () => {
    if (!result) {
      return;
    }

    await submit([
      {
        itemId,
        optionId,
        value: result
      }
    ]);
  };

  const rectify = async () => {
    setIsAudioRecording(false);

    if (!data) {
      return;
    }

    const res =
      inputType === 'voice'
        ? await webClient.api.tools.rectifierVoice(blobData(), {
            type: RectifyType.SURVEY_RESPONSE,
            reference: topicId || code || project
          })
        : await webClient.api.tools.rectifier({
            input: stringData(),
            type: RectifyType.SURVEY_RESPONSE,
            reference: topicId || code || project
          });

    if (res.data.data.content.length > 10) {
      setResult(res.data.data.content);
    }
  };

  if (result) {
    return (
      <Box
        borderRadius={{
          xs: `calc((100% - ${theme.spacing(
            2
          )}) / 2) calc((100% - ${theme.spacing(2)}) / 2) 24px 24px`,
          sm: '300px 300px 24px 24px'
        }}
        boxShadow={`0 0 10px 2px ${addAlphaToHex('#000', 10)}`}
        mx={{
          xs: 1,
          sm: 'calc((100% - 600px) / 2)'
        }}
        display='flex'
        flexDirection='column'
        py={6}
        px={32 / 12}
      >
        <WriterSvg />
        <Typography
          variant='body1'
          fontSize={20}
          fontWeight={fontWeights.semiBold}
          color='#414042'
          mt={5}
        >
          {t('ThisIsSummaryOfWhatYouShared')}
        </Typography>
        <Typography
          variant='body1'
          fontSize={18}
          fontWeight={fontWeights.extraLight}
          fontStyle='italic'
          color='#414042'
          mt={32 / 12}
        >
          <Markdown>{result.clear()}</Markdown>
        </Typography>
        <Box mt={2} alignSelf='center'>
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={submitResult}
          >
            {t('$Submit')}
          </Button>
          <Button
            type='button'
            variant='outlined'
            color='primary'
            onClick={() => setResult('')}
            sx={{
              ml: 16 / 12
            }}
          >
            {t('$MakeItBetter')}
          </Button>
        </Box>
      </Box>
    );
  }

  if (inputType) {
    return (
      <Box
        mx={{
          xl: 'calc((100% - 1440px) / 2)'
        }}
      >
        <Typography fontSize={27} fontWeight={fontWeights.medium}>
          {t('BeSpecificTryToGiveExamples')}
        </Typography>
        <Select
          sx={{
            mt: 4,
            zIndex: 1
          }}
          value={inputType}
          onChange={(e) => setInputType(e.target.value as 'text' | 'voice')}
        >
          <MenuItem value={'voice'}>
            <Box
              display='flex'
              alignItems='center'
              pr={4}
              color={theme.palette.primary.main}
            >
              <Mic sx={{ mr: 0.2 }} />
              {t('Talk')}
            </Box>
          </MenuItem>
          <MenuItem value={'text'}>
            <Box
              display='flex'
              alignItems='center'
              pr={4}
              color={theme.palette.primary.main}
            >
              <Keyboard sx={{ mr: 0.2 }} />
              {t('Write')}
            </Box>
          </MenuItem>
        </Select>
        <Box mt={-8} display='flex' flexDirection='row' alignItems='flex-end'>
          {inputType === 'voice' ? (
            <Box>
              <SoundRecorder
                reference={(x) => (soundRecorder = x)}
                onMicNotAvailableOrGranted={() => setInputType('text')}
                onStartRecording={() => setIsAudioRecording(true)}
                onRecording={(time) => setRecordedTime(time)}
                onEndRecording={(data, time) => {
                  setIsAudioRecording(false);
                  setData(data);
                  setRecordedTime(time);
                }}
                minimumNeed={minimumNeed}
              />
              <Typography
                textAlign='center'
                mt={-8}
                fontSize={20}
                fontWeight={fontWeights.regular}
                color={theme.palette.primary.main}
              >
                <Markdown>
                  {recordedTime < 2e3
                    ? ''
                    : recordedTime < 1e4
                    ? t('GoodStartKeepItUp')
                    : recordedTime < 6e4
                    ? t('TalkForAtleast', { sec: 60 })
                    : recordedTime < 18e4
                    ? t('MoreDetailsMoreValue')
                    : t('PerfectClickSendToProceed')}
                </Markdown>
              </Typography>
              <Box textAlign='center' mt={3} mb={-4}>
                <Button
                  variant='contained'
                  type='button'
                  sx={{
                    mr: 2
                  }}
                  onClick={rectify}
                  disabled={
                    !data || isAudioRecording || recordedTime < minimumNeed
                  }
                >
                  {t('$Send')}
                </Button>
                <Button
                  variant='contained'
                  type='button'
                  disabled={isAudioRecording}
                  onClick={() => soundRecorder?.startOver()}
                  sx={{
                    bgcolor: 'white',
                    color: theme.palette.primary.main,
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: 'none',
                      color: 'white'
                    }
                  }}
                >
                  {t('StartOver')}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              flex='auto 1 1'
              mt={12}
              mr={10}
              bgcolor='white'
              border='solid 1px rgba(188,19,19,0.2)'
              borderRadius='24px'
              p={{
                xs: 16 / 12,
                sm: 32 / 12
              }}
              display='flex'
              flexDirection='column'
              position='relative'
            >
              <Box
                position='absolute'
                top='16px'
                right='16px'
                zIndex={1}
                color={theme.palette.primary.main}
              >
                <Box
                  component='span'
                  color={
                    stringData().length < dataMinLength ? '#EC4849' : '#32EFBE'
                  }
                >
                  {stringData().length}
                </Box>
                <Box component='span'>/{dataMinLength}</Box>
              </Box>
              <TextField
                type='text'
                name='comment'
                multiline
                rows={3}
                value={data}
                placeholder={`Write at least ${dataMinLength} characters`}
                onChange={(e) => setData(e.target.value)}
                sx={{
                  '& fieldset': {
                    border: 'none',
                    outline: 'none'
                  }
                }}
              />
              <IconButton
                sx={{
                  mt: 1,
                  alignSelf: 'flex-end'
                }}
                type='button'
                onClick={rectify}
                disabled={stringData().length < dataMinLength}
              >
                <Send fontSize='large' />
              </IconButton>
            </Box>
          )}
          <Box
            boxShadow={`0 0 10px 2px ${addAlphaToHex(
              theme.palette.primary.main,
              25
            )}`}
            borderRadius='24px'
            py={4}
            px={2}
            bgcolor='white'
            flex='auto 1 1'
          >
            <Markdown>{topic.items[0].name.clear()}</Markdown>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      mx={{
        sm: 'calc((100% - 540px) / 2)'
      }}
    >
      <Typography fontSize={27} fontWeight={fontWeights.bold}>
        {t('HowDoYouSeeIn', {
          name: false ? '' : t('$Feedback.Yourself'),
          topic: topic.name
        })}
      </Typography>
      <Box
        mt={32 / 12}
        borderRadius='24px'
        boxShadow={`0 0 10px 2px ${addAlphaToHex(
          theme.palette.primary.main,
          10
        )}`}
        p={4}
        bgcolor='white'
      >
        <Markdown>{topic.items[0].name.clear()}</Markdown>
      </Box>
      <Typography mt={4} fontSize={27} fontWeight={fontWeights.bold}>
        {t('ChooseTheOptionToTypeOrTalk')}
      </Typography>
      <Typography fontSize={24} fontWeight={fontWeights.light}>
        {t('BeSpecificTryToGiveExamples')}
      </Typography>
      <Box
        mt={40 / 12}
        sx={{
          '&>button': {
            bgcolor: 'white',
            borderRadius: '24px',
            width: '133px',
            height: '133px',
            display: 'inline-flex',
            flexDirection: 'column',
            fontSize: '24px',
            boxShadow: `0 2px 4px ${addAlphaToHex('#000', 25)}`,
            '&>svg': {
              fontSize: '38px',
              mb: 8 / 12
            }
          }
        }}
      >
        <Button
          type='button'
          onClick={() => setInputType('voice')}
          sx={{
            mr: 6
          }}
        >
          <Mic />
          {t('Talk')}
        </Button>
        <Button type='button' onClick={() => setInputType('text')}>
          <Keyboard />
          {t('Write')}
        </Button>
      </Box>
      <Typography fontSize={14} fontWeight={fontWeights.light} mt={64 / 12}>
        <Markdown>{t('YourInputsWillBeParaphrased')}</Markdown>
      </Typography>
    </Box>
  );
};

export default OpenEnded;
