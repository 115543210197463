import { CharacterIdle } from '@/animations';
import { Behaviour, Markdown, useTranslation } from '@/components';
import {
  languageChangeSubscribe,
  languageChangeUnsubscribe
} from '@/components/multi-lang';
import '@/extensions/array.extensions';
import '@/extensions/string.extensions';
import { fontWeights, getCdnUrl } from '@/helpers';
import { useAppContext } from '@contexts/app-context';
import { Box, Button, Hidden, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

type IProps = {
  user: {
    name: string;
    email: string;
  };
  topic: SurveyToResponseResponse | TopicSurveyResponse;
  project?: string;
  code?: string;
  name?: string;
  relationship?: string;
  submit: Func1<CreateSurveyRespondItemBody[], Promise<void>>;
};

const OnePageCheckbox = ({ topic, code, name, ...props }: IProps) => {
  const { showToast } = useAppContext();
  const t = useTranslation('Feedback.OnePage');

  const [items, setItems] = useState<string[]>([]);

  useEffect(() => {
    const languageSubscriptionId = languageChangeSubscribe(() => setItems([]));
    return () => languageChangeUnsubscribe(languageSubscriptionId);
  }, []);

  const getSelectedOption = () =>
    topic?.options?.find((x) => !x.optional) || {
      id: '',
      value: 1,
      minSelect: 2,
      maxSelect: 4
    };

  const toggleTag = async (id: string) => {
    const { minSelect, maxSelect } = getSelectedOption();
    if (items.find((x) => x === id) !== undefined) {
      return setItems(items.filter((x) => x !== id));
    }

    if (maxSelect! > 0 && items.length === maxSelect!) {
      await showToast(t('MaxChoices', { max: maxSelect! }), 5e3, 'error');
      return;
    }

    setItems([...items, id]);
  };

  const submit = async () => {
    const selectedOption = getSelectedOption();
    if (items.length < selectedOption.minSelect!) {
      await showToast(
        t('YouNeedAtLeastXMore', {
          x: selectedOption.minSelect! - items.length
        }),
        3e3,
        'info'
      );
      return;
    }

    await props.submit(
      items.map((x) => ({
        itemId: x,
        optionId: selectedOption.id,
        value: selectedOption.value
      }))
    );
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      py={{
        xs: 0,
        sm: 4
      }}
      flex='auto 1 1'
      mx={{
        xs: 'unset',
        sm: 'calc((100vw - 768px) / 2)',
        md: 'calc((100vw - 900px) / 2)',
        lg: 'calc((100vw - 1100px) / 2)'
      }}
    >
      <Typography variant='h2' sx={{ mt: 3 }}>
        <Markdown>
          {!code
            ? t('WhichOfTheseDescribeYouBest')
            : t('GreatToSeeYou', { name })}
        </Markdown>
      </Typography>
      <Typography variant='body2' sx={{ fontSize: 18, my: 1, zIndex: 1 }}>
        <Markdown>
          {t('PickMinToMax', {
            name,
            min: getSelectedOption().minSelect!,
            max: getSelectedOption().maxSelect!
          })}
        </Markdown>
      </Typography>
      {!!code && (
        <Typography
          variant='h3'
          fontSize='17px'
          mt={2}
          fontWeight={fontWeights.medium}
        >
          {t('IThink', { name })}
        </Typography>
      )}
      <Box
        bgcolor='#f5f5f5'
        mt={4}
        borderRadius='10px'
        display='flex'
        flexDirection='row'
      >
        <CharacterIdle />
        <Box
          sx={{
            '>:not(:last-of-type)': {
              mb: 1
            }
          }}
          p={1.5}
          flex={{
            xs: 'auto 1 1',
            sm: '50% 0 0'
          }}
          display='flex'
          flexDirection='column'
          alignItems='stretch'
        >
          {topic.items.map((x) => (
            <Behaviour
              text={x.name}
              description={x.description}
              value={x.id}
              key={x.name}
              active={items.any((y) => y === x.id)}
              onClick={toggleTag}
            />
          ))}
          <Hidden smDown>
            <Button
              sx={{ mt: 2, alignSelf: 'flex-start' }}
              variant='contained'
              color='primary'
              onClick={submit}
              disabled={items.length < getSelectedOption().minSelect!}
            >
              {t('$Submit')}
            </Button>
          </Hidden>
        </Box>
        <Hidden smDown>
          <Box
            flex='50% 0 0'
            borderRadius='0 10px 10px 0'
            sx={{
              backgroundImage: `url(${getCdnUrl('/images/pattern1.jpg')})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          />
        </Hidden>
      </Box>
      <Hidden smUp>
        <Button
          sx={{ mt: 2 }}
          variant='contained'
          color='primary'
          onClick={submit}
          disabled={items.length < getSelectedOption().minSelect!}
        >
          {t('$Submit')}
        </Button>
      </Hidden>
    </Box>
  );
};

export default OnePageCheckbox;
