import { createContext, useContext } from 'react';

const AppContext = createContext<IAppContext>({} as any);

export function useAppContext() {
  return useContext(AppContext);
}

export type AppContextType = React.ContextType<typeof AppContext>;

export default AppContext;
