import { HollowCircle } from '@/components';
import { fontWeights, getCdnUrl } from '@/helpers';
import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const textsRadius = 150;

interface IProps {
  width?: number;
  minimumNeed?: number;
  perfect?: number;
  maxRecordingTime?: number;
  reference?: (reference: { startOver(): void }) => void;
  onMicNotAvailableOrGranted?: () => void;
  onStartRecording?: () => void;
  onRecording?: (time: number) => void;
  onEndRecording?: (audio: Blob, time: number) => void;
}

const SoundRecorder = ({
  maxRecordingTime,
  minimumNeed,
  perfect,
  width,
  reference,
  onStartRecording,
  onRecording,
  onEndRecording,
  onMicNotAvailableOrGranted
}: IProps) => {
  const theme = useTheme();

  const [percent, setPercent] = useState(0);
  const [volume, setVolume] = useState(50);
  const [isMicAvailable, setIsMicAvailable] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const recordedDataRef = useRef<Blob[]>([]);
  const audioContextRef = useRef<AudioContext>();
  const analyserRef = useRef<AudioWorkletNode>();
  const microphoneRef = useRef<MediaStreamAudioSourceNode>();
  const recorderRef = useRef<MediaRecorder>();
  const [intervalId, setIntervalId] = useState<any>();
  const [recordedTime, setRecordedTime] = useState(0);

  const getMaxRecordingTime = () => maxRecordingTime || 18e4;
  const getMinimumNeed = () => minimumNeed || 6e4;
  const getPerfect = () => perfect || 15e4;
  const playShortAudio = async (src: string) => {
    const audioPlayer = new Audio(src);
    audioPlayer.addEventListener('ended', () => {
      try {
        audioPlayer.remove();
      } catch (exp) {}
    });
    await audioPlayer.play();
  };

  const openAudioStream = async () => {
    try {
      const audioContext = new AudioContext();
      await audioContext.audioWorklet.addModule('worklet.js');
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });
      const microphone = audioContext.createMediaStreamSource(stream);
      const analyser = new AudioWorkletNode(audioContext, 'volume-processor');
      analyser.port.onmessage = (e) => {
        const v = e.data * 400;
        setVolume(v > 100 ? 100 : v);
      };
      microphone.connect(analyser);
      setIsMicAvailable(true);

      audioContextRef.current = audioContext;
      microphoneRef.current = microphone;
      analyserRef.current = analyser;
    } catch (exp) {
      if (onMicNotAvailableOrGranted) {
        onMicNotAvailableOrGranted();
      }
      console.log(exp);
    }
  };

  const getAudioBlob = () =>
    new Blob(recordedDataRef.current, {
      type: 'audio/wav'
    });

  const closeAudioStream = async () => {
    if (!audioContextRef || !microphoneRef.current || !analyserRef.current) {
      return;
    }

    await audioContextRef.current?.suspend();
    microphoneRef.current.disconnect(analyserRef.current);
    microphoneRef.current.mediaStream.getAudioTracks().forEach((x) => x.stop());
  };

  const getWidth = () => width || 662;
  const convert = (x: number) => Math.round(x * (getWidth() / 662) * 100) / 100;
  const progress = () => (volume / 100) * convert(171) + convert(150);

  const start = async () => {
    if (intervalId) {
      setVolume(50);
      setIsRecording(false);
      clearInterval(intervalId);
      setIntervalId(undefined);

      await playShortAudio(getCdnUrl('/sounds/recording-end.mp3'));
      recorderRef.current?.stop();
      closeAudioStream();
      if (onEndRecording) {
        onEndRecording(getAudioBlob(), recordedTime);
      }
      return;
    }

    if (onStartRecording) {
      onStartRecording();
    }

    setIsRecording(true);
    startOver();
    await openAudioStream();
    if (microphoneRef.current) {
      const recorder = new MediaRecorder(microphoneRef.current.mediaStream);
      recorder.addEventListener('dataavailable', onDataAvailable);
      recorder.start(1000);

      recorderRef.current = recorder;
    }

    await playShortAudio(getCdnUrl('/sounds/recording-start.mp3'));
    setIntervalId(
      setInterval(async () => {
        if (percent < 1) {
          setRecordedTime((x) => x + 100);
          setVolume(Math.random() * 100);

          if (onRecording) {
            onRecording(recordedTime);
          }
        } else {
          start();
        }
      }, 100)
    );
  };

  const onDataAvailable = (e: BlobEvent) =>
    recordedDataRef.current.push(e.data);

  const startOver = () => {
    recordedDataRef.current = [];
    setRecordedTime(0);
    setPercent(0);
    setVolume(50);
  };

  if (reference) {
    reference({
      startOver
    });
  }

  useEffect(() => {
    openAudioStream().then(closeAudioStream);
  }, []);

  useEffect(() => {
    setPercent(recordedTime / getMaxRecordingTime());
  }, [recordedTime]);

  return (
    <Box
      component='svg'
      sx={{
        '& > circle': {
          cx: getWidth() / 2,
          cy: getWidth() / 2
        }
      }}
      viewBox={`0 0 ${getWidth()} ${getWidth()}`}
      width={getWidth()}
      height={getWidth()}
    >
      <defs>
        <path
          id='curve'
          d={`M${getWidth() / 2},${getWidth() / 2}m${convert(
            textsRadius
          )},0a${convert(textsRadius)},${convert(textsRadius)},0,1,0,-${
            convert(textsRadius) * 2
          },0a${convert(textsRadius)},${convert(textsRadius)},0,1,0,${
            convert(textsRadius) * 2
          },0z`}
          fill='none'
          transform='scale(-1,1) rotate(270)'
          style={{
            transformOrigin: 'center'
          }}
        />
        <filter id='dropShadow1' width={convert(144)} height={convert(144)}>
          <feOffset in='SourceGraphic' dx={0} dy={4} />
          <feColorMatrix
            type='matrix'
            values='0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0'
          />
          <feGaussianBlur stdDeviation={4} />
          <feBlend in='SourceGraphic' in2='blurOut' />
        </filter>
        <filter id='dropShadow2' width={convert(112)} height={convert(112)}>
          <feOffset in='SourceGraphic' dx={0} dy={2} />
          <feColorMatrix
            type='matrix'
            values='0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0'
          />
          <feGaussianBlur stdDeviation={4} />
          <feBlend in='SourceGraphic' in2='blurOut' />
        </filter>
      </defs>
      <HollowCircle
        radius={getWidth() / 2}
        innerRadius={progress() + convert(6)}
        stroke='#BC3DF414'
        strokeOpacity={0.3}
      />
      <HollowCircle
        radius={progress() - convert(6)}
        innerRadius={convert(140)}
        stroke='#BC3DF414'
        strokeOpacity={0.4}
      />
      <HollowCircle
        radius={convert(140)}
        innerRadius={convert(108)}
        stroke='#F7F7F7'
        filter='url(#dropShadow1)'
      />
      <HollowCircle
        radius={convert(140)}
        innerRadius={convert(108)}
        stroke='#BC3DF4'
        progress={percent}
      />
      <circle
        r={convert(108)}
        fill='#fff'
        filter='url(#dropShadow2)'
        onClick={start}
      />
      {(!isRecording || recordedTime % 1000 < 500) && (
        <circle
          r={convert(11)}
          fill={
            isRecording ? theme.palette.success.main : theme.palette.error.main
          }
          transform={`translate(-${convert(34)}, -${convert(1)})`}
        />
      )}
      <text
        x={getWidth() / 2}
        y={getWidth() / 2}
        alignmentBaseline='middle'
        textAnchor='middle'
        transform={`translate(${convert(14)}, 0)`}
        fontWeight={fontWeights.medium}
        fontSize={convert(24)}
      >
        {Math.floor(recordedTime / 6e4).toFixedLength(2)}:
        {Math.floor((recordedTime / 1000) % 60).toFixedLength(2)}
      </text>
      <text fontSize={24} fill={theme.palette.primary.main}>
        <textPath
          xlinkHref='#curve'
          textAnchor='end'
          startOffset={`${(getMinimumNeed() / getMaxRecordingTime()) * 100}%`}
        >
          Minimum need|
        </textPath>
      </text>
      <text fontSize={24} fill={theme.palette.primary.main}>
        <textPath
          xlinkHref='#curve'
          textAnchor='end'
          startOffset={`${(getPerfect() / getMaxRecordingTime()) * 100}%`}
        >
          Perfect|
        </textPath>
      </text>
    </Box>
  );
};

export default SoundRecorder;
