import { Loading } from '@/components';
import CustomizedThemeProvider from '@/components/customized-theme-provider';
import {
  languageChangeSubscribe,
  languageChangeUnsubscribe
} from '@/components/multi-lang';
import { webClient } from '@/helpers';
import ErrorComponent from '@/pages/error';
import AppContext from '@contexts/app-context';
import TeamReportTable from '@survey/report/team-report-table';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

interface IParams {
  projectId: string;
  teamId: string;
}

const TeamReport = () => {
  const { setError } = useContext(AppContext);
  const { projectId, teamId } = useParams<'projectId' | 'teamId'>() as IParams;

  const [data, setData] = useState<TeamReportResponse>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      return;
    }

    function loadReport() {
      setLoading(true);
      webClient.api.reports
        .teamReport(projectId, teamId, {
          loadingHandled: true
        })
        .then((res) => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }

    loadReport();

    const languageSubscriptionId = languageChangeSubscribe(loadReport);

    return () => languageChangeUnsubscribe(languageSubscriptionId);

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <ErrorComponent
        code='404'
        context={{
          setError
        }}
      />
    );
  }

  const render = () => (
    <>
      <Helmet>
        <style>{`body { background-image: none !important }`}</style>
      </Helmet>
      <TeamReportTable {...data} />
    </>
  );

  if (
    data.customizations &&
    (data.customizations.primaryColor || data.customizations.secondaryColor)
  ) {
    return (
      <CustomizedThemeProvider customizations={data.customizations}>
        {render()}
      </CustomizedThemeProvider>
    );
  }

  return render();
};

export default TeamReport;
