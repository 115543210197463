import { centerOf } from '@/helpers';
import { Box } from '@mui/material';
import { AIReport, ChapterCover } from '@survey/report/components';
import TableAnonymous from './table-anonymous';
import TableAverage from './table-average';
import TableDetailed from './table-detailed';
import TableFamiliarity from './table-familiarity';

type IProps = TopicItemResponse & {
  index?: string;
  cover?: string;
  reports?: SimpleTableReportResponses[];
  aiReports?: SimpleAIReportResponse[];
  options: TopicOptionResponse[];
  relations?: TopicRelationResponse[];
  familiarity?: TopicFamiliarityResponse;
  section?: string;
  charts?: ChartResponse[];
};

const ReportItem = ({
  id,
  index,
  cover,
  name,
  reports,
  aiReports,
  options,
  section,
  relations,
  familiarity,
  charts
}: IProps) => {
  const aiReport = aiReports?.find((x) => x.itemId === id);

  return (
    <Box id={id.replace(/[-_]/g, '')} key={`item_${id}`}>
      <ChapterCover image={cover} index={index} name={name} section={section} />
      <Box
        p={{
          xs: 8 / 12,
          sm: centerOf(8 / 12, 4),
          md: 4
        }}
        pb={{
          xs: 2,
          sm: 3,
          md: 4
        }}
      >
        {reports &&
          reports.map((x) =>
            x.type === 'DETAILED' ? (
              <TableDetailed
                key={`item_${id}_detailedTable`}
                id={id}
                options={options}
                relations={relations}
                reports={x.items.filter((y) => y.itemId === id)}
              />
            ) : x.type === 'AVERAGE' ? (
              <TableAverage
                key={`item_${id}_averageTable`}
                id={id}
                relations={relations}
                reports={x.items.filter((y) => y.itemId === id)}
              />
            ) : x.type === 'ANONYMOUS' ? (
              <TableAnonymous
                key={`item_${id}_anonymousTable`}
                id={id}
                options={options}
                reports={x.items.filter((y) => y.itemId === id)}
              />
            ) : x.type === 'FAMILIARITY' ? (
              <TableFamiliarity
                key={`item_${id}_familiarityTable`}
                id={id}
                options={options}
                reports={x.items.filter((y) => y.itemId === id)}
                familiarity={familiarity}
              />
            ) : (
              <></>
            )
          )}
        {aiReport && (
          <AIReport title={name} content={aiReport.content} charts={charts} />
        )}
      </Box>
    </Box>
  );
};

export default ReportItem;
